<template>
  <!-- end:: Header -->
  <div
    class="kt-content kt-content--fit-top kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
    id="kt_content"
  >
    <!-- begin:: Content -->

    <!-- begin:: hero -->
    <div class="kt-sc-license" style="background-image: url('/assets/media/bg/bg-9.jpg')">
      <div class="kt-container">
        <div class="kt-sc__top">
          <h3 class="kt-sc__title">Subscription Plan</h3>
          <div class="kt-sc__nav">
            <a href="#" class="kt-link kt-link--light kt-font-bold">Help Center</a>
          </div>
        </div>
      </div>
    </div>

    <!-- end:: hero -->
    <div class="kt-negative-spacing--7"></div>

    <!-- begin:: infobox -->
    <div class="kt-grid__item">
      <div class="kt-container">
        <div class="kt-portlet">
          <div class="kt-portlet__body">
            <div class="kt-infobox">
              <div class="kt-infobox__body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="kt-infobox__section">
                      <div class="kt-infobox__content">
                        <h2 class="kt-infobox__subtitle">Website Subscription Plan</h2>
                      </div>
                    </div>
                    <!-- <div class="kt-infobox__section">
                      <div class="kt-infobox__content kt-infobox__content--md">
                        <p>
                          The point here is that anyone can ramble on and on or even write brief statement about something that really
                          <a href="javascript:;">Download Sample</a>. If you want people to continue to follow you and your blog Your blog post fonts are automatically styled according to your site's theme. However, you can change the text font and format by selecting the text and clicking the options.
                        </p>
                      </div>
                    </div>-->
                    <div class="kt-infobox__section">
                      <div class="kt-infobox__content">
                        <div class="table-responsive">
                          <table class="table table-light table-light--info">
                            <thead>
                              <tr>
                                <th></th>
                                <th class="table-center">Free</th>
                                <th class="table-center">Lite</th>
                                <th class="table-center">Standard</th>
                                <th class="table-center">Ecommerce</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="kt-font-bold table-row-title">Storage</td>
                                <td class="table-center">500 MB</td>
                                <td class="table-center">5 GB</td>
                                <td class="table-center">10 GB</td>
                                <td class="table-center">10 GB</td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Business Listing</td>
                                <td class="table-center">
                                  <i class="flaticon2-check-mark text-success"></i>
                                </td>
                                <td class="table-center"> 
                                  <i class="flaticon2-check-mark text-success"></i>
                                  <!-- <i class="flaticon2-cross text-danger"></i> -->
                                </td>
                                <td class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Bandwidth</td>
                                <td class="table-center">Unlimited</td>
                                <td class="table-center">Unlimited</td>
                                <td class="table-center">Unlimited</td>
                                <td class="table-center">Unlimited</td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Content Delivery Network</td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Free Subdomain</td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Ads</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Connect own domain</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Premium Plugins</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">SSL Certificate</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">E-commerce Support</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Accept Khalti Payment by Yelko</td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Accept Khalti Payment - Business</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Accept Esewa Payment</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Accept International Payment</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title">Report & Analytics</td>
                                <td class="table-center"><i class="flaticon2-cross text-danger"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                                <td class="table-center"><i class="flaticon2-check-mark text-success"></i></td>
                              </tr>
                              <tr>
                                <td class="kt-font-bold table-row-title"></td>
                                <td class="table-center"><v-btn class="btn btn-info">Select</v-btn></td>
                                <td class="table-center"><v-btn class="btn btn-info">Select</v-btn></td>
                                <td class="table-center"><v-btn class="btn btn-info">Select</v-btn></td>
                                <td class="table-center"><v-btn class="btn btn-info">Select</v-btn></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="kt-infobox__section">
                      <div class="kt-infobox__content kt-infobox__content--md">
                        <p>
                         Note: Price are exclusive of government service tax.
                         </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end:: infobox -->

    <!-- end:: iconbox -->

    <!-- end:: Content -->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "support",
  data() {
    return {
      data: {}
    };
  },
  methods: {},
  computed: {},
  mounted() {
    this.getBillings();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ivoice", route: "dashboard" }
    ]);
  }
};
</script>